import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'src/components/styled'
import { Tooltip } from '@verbit-ai/verbit-ui-library'
import { ZoomAudio, TeamsAudio } from 'src/components/icons'
import { useLiveMachine } from 'src/state/state-machines/LiveMachine/LiveMachineProvider'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { Button, Typography } from '@verbit-ai/verbit-ui-library'
import { VerbitConnect, VerbitConnectProvider } from '@verbit-ai/verbit-connect-web'
import { getMixpanelVcApiKey, getRollbarApiKey } from '../../utils/env'
import { useFeatureFlag } from '../../state/FeatureFlagProvider'
import { ElapsedTimeValue } from './ElapsedTimeValue'

const StyledButton = styled(Button)`
    display: flex;

    &:hover {
        border: none;
    }
`

const StyledSpan = styled.span`
    border-radius: 4px;

    &:hover {
        border: 1px solid ${theme?.palette?.grey?.[8]};
        box-shadow: none;
    }
`

const MediaSourceContainer = styled.div`
    display: inline-block;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: auto;
`

type MediaSourceState = 'Streaming' | 'Ended' | 'Awaiting audio' | 'Loading...'

export const MediaSource = (): ReactElement => {
    const [mediaState, setMediaState] = useState<MediaSourceState>('Loading...')
    const [orderId, setOrderId] = useState<string>()

    const [
        {
            context: { controls, mediaSource, redirect_urls },
        },
    ] = useLiveMachine(['controls', 'mediaSource', 'redirect_urls'])
    const { sessionStatus } = useSessionStatus([
        'sessionStatus.playbackState',
        'sessionStatus.audioStartedAt',
        'sessionStatus.orderId',
    ])

    useEffect(() => {
        switch (sessionStatus?.playbackState.toLowerCase()) {
            case 'playing':
                setMediaState('Streaming')
                break
            case 'stopped':
                setMediaState('Ended')
                break
            case 'not_started':
                setMediaState('Awaiting audio')
                break
            default:
                setMediaState('Loading...')
                break
        }
    }, [sessionStatus?.playbackState])

    useEffect(() => {
        setOrderId(sessionStatus?.orderId)
    }, [sessionStatus?.orderId])

    const VERBIT_CONNECT_URL = redirect_urls?.verbit_connect_url
    const isMediaComponentVisible = controls?.toolbar?.media_component?.visible
    if (!isMediaComponentVisible) return <></>

    const MediaSourceAndTimeCode = () => {
        return (
            <>
                <MediaSourceContainer>
                    <Typography
                        color={
                            mediaState === 'Streaming'
                                ? theme?.palette?.green?.[3]
                                : [
                                      mediaState !== 'Ended'
                                          ? theme?.palette?.grey?.[8]
                                          : theme?.palette?.grey?.[10],
                                  ]
                        }
                        italic
                        size="md"
                    >
                        {mediaState}
                    </Typography>
                </MediaSourceContainer>

                {sessionStatus?.playbackState &&
                    sessionStatus?.playbackState.toLowerCase() !== 'stopped' && (
                        <ElapsedTimeValue />
                    )}
            </>
        )
    }

    const handleAudioRecorder = () => {
        if (VERBIT_CONNECT_URL) {
            window.open(String(VERBIT_CONNECT_URL), '_blank')
        }
    }

    const Teams = () => {
        return (
            <StyledButton
                variant="light"
                leftIcon={<TeamsAudio />}
                size="lg"
                style={{ cursor: 'help', minWidth: '30px' }}
            >
                <MediaSourceAndTimeCode />
            </StyledButton>
        )
    }

    const Zoom = () => {
        return (
            <StyledButton
                variant="light"
                leftIcon={<ZoomAudio />}
                size="lg"
                style={{ cursor: 'help', minWidth: '30px' }}
            >
                <MediaSourceAndTimeCode />
            </StyledButton>
        )
    }

    // Note: tooltip is moved here to avoid the tooltip from being rendered multiple times
    // when the timer is running
    const MediaComponent: { [key: string]: JSX.Element } = {
        zoom: (
            <Tooltip label={`Audio stream using Zoom`} placement="top" hasArrow>
                <StyledSpan>
                    <Zoom />
                </StyledSpan>
            </Tooltip>
        ),
        teams: (
            <Tooltip label={`Audio stream using Teams`} placement="top" hasArrow>
                <StyledSpan>
                    <Teams />
                </StyledSpan>
            </Tooltip>
        ),
        verbit_connect: orderId ? (
            <VerbitConnectProvider
                orderId={orderId}
                rollbarToken={getRollbarApiKey()}
                mixpanelToken={getMixpanelVcApiKey()}
            >
                <VerbitConnect />
            </VerbitConnectProvider>
        ) : (
            <React.Fragment />
        ),
        default: <React.Fragment />,
    }

    return (mediaSource && MediaComponent[mediaSource?.toLowerCase()]) || MediaComponent.default
}
export default MediaSource
